import React from 'react';
import styles from './rotated-title.module.css';

export default props => (
  <div className={styles.container}>
    <h1>
      <div className={styles.antiRotatedContainer}>
        <span>{props.titleTop}</span>
      </div>
      <div className={styles.rotatedContainer}>
        <span>{props.titleBottom}</span>
      </div>
    </h1>
  </div>
);
