import React from 'react';
import { Link, navigate } from 'gatsby';
import { FaCaretDown } from 'react-icons/fa';
import layoutStyles from './layout.module.css';
import logoPink from '../img/logo2.svg';
import logoGreen from '../img/logo.svg';
import cart from '../img/cart-white.svg';
import ctaMiaou from '../img/cta-miaou.svg';
import facebook from '../img/facebook.svg';
import instagram from '../img/instagram.svg';
import twitter from '../img/twitter.svg';
import { isLoggedIn, getCurrentUser, recoverUser, logout, confirmEmailChangeByToken } from '../services/auth';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import SEO from './seo';

const ListLink = props => (
  <Link className={props.className} style={props.style} to={props.to}>
    {props.children}
  </Link>
);

const AuthenticationDependantLink = props => {
  if (isLoggedIn() !== props.shouldBeAuthenticated) {
    return null;
  }
  return (
    <Link to={props.to} onClick={props.onClick} style={props.style}>
      {props.children}
    </Link>
  );
};

const AuthenticationDependantDropdown = props => {
  if (isLoggedIn() !== props.shouldBeAuthenticated) {
    return null;
  }
  return (
    <span style={props.style} className={layoutStyles.dropdown}>
      Mon Compte <FaCaretDown />
      <div className={layoutStyles.dropdownContent}>
        <span>
          ✨{props.firstname} {props.lastname}
        </span>
        <hr />
        <ul>
          <li>
            <Link to="app/profile">Paramètres</Link>
          </li>
          <li>
            <Link to="app/profile/subscription">Abonnement</Link>
          </li>
          <li>
            <Link to="app/profile/cats">Mes chats</Link>
          </li>
        </ul>
        <hr />
        <button onClick={logout}>Déconnexion</button>
      </div>
    </span>
  );
};

const EmailSubscriptionFeedback = props => {
  if (props.state.result === 'success') {
    return (
      <p className="italic ml-5" style={{ color: 'var(--bisky-green)' }}>
        {props.state.msg}
      </p>
    );
  }
  if (props.state.result === 'error') {
    return (
      <p className="italic ml-5" style={{ color: 'var(--bisky-red)' }}>
        {props.state.msg}
      </p>
    );
  }
  return <p />;
};

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { user_metadata: {} },
      email: ``,
      emailHelp: {
        result: ``,
        msg: ``
      }
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }
  openNav() {
    document.getElementById('mySidenav').style.width = '250px';
  }
  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmission() {
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!validateEmail(this.state.email)) {
      this.setState({ emailHelp: { result: `error`, msg: `Veuillez saisir un email valide` } });
      return;
    }
    this.setState({ emailHelp: `` });
    addToMailchimp(this.state.email)
      .then(data => {
        let message;
        if (data.msg.includes('already')) {
          message = `Vous êtes déjà inscrit à la Biskyletter`;
        } else if (data.msg.includes(`Thank you for subscribing!`)) {
          message = `Merci pour votre abonnement !`;
        } else {
          message = data.msg;
        }
        this.setState({
          emailHelp: {
            result: data.result,
            msg: message
          }
        });
      })
      .catch(e => {
        this.setState({ emailHelp: { result: `error`, msg: e.message } });
      });
  }

  /**
   * Takes a hash string, parses it, and returns the result
   * @param {string} hash e.g. '#recovery_token=6cefeMxfBVjcTew5t9Gj1Q&foo=bar'
   * @return {object} the parser result e.g { recovery_token: '6cefeMxfBVjcTew5t9Gj1Q' }
   */
  parseHash(hash) {
    if (!hash) return {};

    return (
      // #recovery_token=42&foo=bar&baz
      hash
        // recovery_token=42&foo=bar&baz
        .substring(1)
        // ['recovery_token=42', 'foo=bar', 'baz']
        .split('&')
        // [ ['recovery_token', '42'], ['foo', 'bar'], ['baz'] ]
        .map(v => v.split('='))
        // { recovery_token: '42', foo: 'bar', baz: null }
        .reduce((prev, curr) => {
          prev[curr[0]] = curr[1] || null;
          return prev;
        }, {})
    );
  }

  /**
   * Uses Netlify Identity to check the given recovery_token
   * If valid, redirect to the reset-password page
   * @param {string} token The token to validate again Netlify Identity
   * @return {void}
   */
  recoverUser(token) {
    recoverUser(token)
      .then(response => navigate('/reset-password'))
      .catch(err => {
        console.error('Recover user:' + err.message);
      });
  }

  componentDidMount() {
    this.testForAccountRecovery();
    this.testForEmailChangeToken();
    this.initUserIdTracking();
    this.testForUtmParams();
  }

  /**
   * Takes a search string, parses it, and returns the result
   * @param {string} search
   * @return {object} the parser result
   * TODO: this is clearly redundant with parseHash()
   */
  parseSearch(search) {
    if (!search) return {};

    return search
      .substring(1)
      .split('&')
      .map(v => v.split('='))
      .reduce((prev, curr) => {
        prev[curr[0]] = curr[1] || null;
        return prev;
      }, {});
  }

  testForUtmParams() {
    const searchObj = this.parseSearch(this.props.location.search);
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    for (const key in searchObj) {
      if (utmKeys.includes(key) && searchObj[key]) {
        localStorage.setItem(key, searchObj[key]);
      }
    }
  }

  /**
   * Looks for the string `recovery_token` in the current URL's hash.
   * If found, trigger the password recovery process
   */
  testForAccountRecovery() {
    const hashObj = this.parseHash(this.props.location.hash);
    if (Object.keys(hashObj).includes('recovery_token')) {
      this.recoverUser(hashObj['recovery_token']);
    }
  }

  /**
   * Looks for the string `email_change_token` in the current URL's hash.
   * If found, trigger the email change process
   */
  testForEmailChangeToken() {
    const hashObj = this.parseHash(this.props.location.hash);
    if (Object.keys(hashObj).includes('email_change_token')) {
      confirmEmailChangeByToken(hashObj['email_change_token'])
        .then(() => {
          alert('Succès ! La modification de votre email est effective.');
        })
        .catch(() => {
          alert(`Impossible de modifier l'email`);
        });
    }
  }

  /**
   * Binds the user's ID (from Netlify Identity) if any to the current
   * Google Analytics Session
   * @see https://support.google.com/analytics/answer/3123666
   */
  initUserIdTracking() {
    if (isLoggedIn()) {
      const idUser = getCurrentUser().id;

      if (!idUser) {
        return console.log('User is undefined');
      }

      if (window.gtag) {
        window.gtag('set', { user_id: `${idUser}` });
      }

      // Store user in local state (usefull for navbar)
      this.setState({
        user: getCurrentUser()
      });
    }
  }

  render() {
    return (
      <div>
        <SEO pathname={this.props.location.pathname} />
        <header className={layoutStyles.header}>
          {/* BURGER MENU*/}
          <span className={layoutStyles.burgerMenu} onClick={this.openNav}>
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </span>

          {/* LOGO */}
          <ListLink className={layoutStyles.headerImage} to="/" style={{ gridArea: 'b' }}>
            <img src={logoPink} alt="logo" style={{ marginBottom: '-10px' }} />
          </ListLink>

          {/* LINKS */}
          <ListLink to="/how-it-works" style={{ gridArea: 'c', fontWeight: 'bold' }}>
            Comment ça marche?
          </ListLink>
          <ListLink to="/recipes" style={{ gridArea: 'd', fontWeight: 'bold' }}>
            Nos recettes
          </ListLink>
          <ListLink to="/story" style={{ gridArea: 'e', fontWeight: 'bold' }}>
            Notre histoire
          </ListLink>
          <AuthenticationDependantLink
            to="/app/login"
            shouldBeAuthenticated={false}
            style={{ gridArea: 'j', fontWeight: 'bold' }}
          >
            Connexion
          </AuthenticationDependantLink>
          <AuthenticationDependantLink
            to="/app/signup"
            shouldBeAuthenticated={false}
            style={{ gridArea: 'k', fontWeight: 'bold' }}
          >
            Inscription
          </AuthenticationDependantLink>
          <AuthenticationDependantDropdown
            firstname={this.state.user.user_metadata ? this.state.user.user_metadata.firstname : null}
            lastname={this.state.user.user_metadata ? this.state.user.user_metadata.lastname : null}
            shouldBeAuthenticated={true}
            style={{ gridArea: 'k', fontWeight: 'bold' }}
          />

          {/* CART */}
          <ListLink className={layoutStyles.headerImage} to="/checkout" style={{ gridArea: 'l' }}>
            <img src={cart} alt="cart" />
          </ListLink>
        </header>

        {/* SIDEBAR */}
        <nav id="mySidenav" className={layoutStyles.sidenav}>
          <button className={layoutStyles.closebtn} onClick={this.closeNav}>
            &times;
          </button>
          <Link to="/how-it-works" onClick={this.closeNav}>
            Comment ça marche?
          </Link>
          <Link to="/recipes" onClick={this.closeNav}>
            Les recettes
          </Link>
          <Link to="/story" onClick={this.closeNav}>
            Notre histoire
          </Link>
          <AuthenticationDependantLink to="/app/login" shouldBeAuthenticated={false} onClick={this.closeNav}>
            Connexion
          </AuthenticationDependantLink>
          <AuthenticationDependantLink to="/app/signup" shouldBeAuthenticated={false} onClick={this.closeNav}>
            Inscription
          </AuthenticationDependantLink>
          <AuthenticationDependantLink to="/app/profile" shouldBeAuthenticated={true} onClick={this.closeNav}>
            Mon Compte
          </AuthenticationDependantLink>
        </nav>

        <main>{this.props.children}</main>

        {this.props.displayFooter ? (
          <footer className={layoutStyles.footer} style={{ fontWeight: 'bold' }}>
            <div className={layoutStyles.footerGridContainer}>
              <Link to="/" style={{ gridArea: 'a', alignSelf: 'center' }}>
                <img src={logoGreen} alt="logo" style={{ marginBottom: '-10px' }} />
              </Link>

              <div style={{ gridArea: 'b' }}>
                <p className="uppercase font-bold mb-3" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  Informations
                </p>
                <nav>
                  <ul className="list-reset" style={{ marginLeft: 0, fontSize: '14px' }}>
                    <li>
                      <Link className="no-underline" to="/terms-and-conditions">
                        Conditions Générales de Vente
                      </Link>
                    </li>
                    <li>
                      <Link className="no-underline" to="/privacy">
                        Politique de confidentialité
                      </Link>
                    </li>
                    <li>
                      <Link className="no-underline" to="/refund">
                        Remboursement
                      </Link>
                    </li>
                    <li>
                      <Link className="no-underline" to="/shipping">
                        Livraison
                      </Link>
                    </li>
                    <li>
                      <Link className="no-underline" to="/faq">
                        Foire Aux Questions
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div style={{ gridArea: 'c' }}>
                <p className="uppercase font-bold mb-3" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  Contact
                </p>
                <address style={{ fontSize: '14px' }}>
                  <p style={{ marginBottom: 0 }}>30 rue de le République</p>
                  <p style={{ marginBottom: 0 }}>75001 Paris</p>
                  <p style={{ marginBottom: 0 }}>05 12 34 56 78</p>
                  <a className="no-underline" href="mailto:hello@bisky.fr">
                    hello@bisky.fr
                  </a>
                </address>
              </div>

              <div style={{ gridArea: 'd' }}>
                <p className="uppercase font-bold mb-3" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  Suivez-nous
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/biskyfamily"
                  className={layoutStyles.socialIcon}
                >
                  <img alt="instagram" src={instagram} className={layoutStyles.socialIcon} />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/biskyfamily"
                  className={layoutStyles.socialIcon}
                >
                  <img alt="facebook" src={facebook} className={layoutStyles.socialIcon} />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/@biskyfamily"
                  className={layoutStyles.socialIcon}
                >
                  <img alt="twitter" src={twitter} className={layoutStyles.socialIcon} />
                </a>
              </div>

              <div style={{ gridArea: 'e' }}>
                <p className="uppercase font-bold mb-3" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  S'abonner à la biskyletter
                </p>
                <input
                  type="email"
                  value={this.state.email}
                  name="email"
                  onChange={this.handleUpdate}
                  placeholder="Email"
                  className="block w-full"
                  style={{
                    height: '52px',
                    paddingLeft: '15px',
                    borderRadius: '3px',
                    outline: 'none',
                    boxShadow: 'rgb(186, 186, 186) 3px 3px 0px 0px'
                  }}
                />
                <EmailSubscriptionFeedback state={this.state.emailHelp} />
                <img
                  onClick={this.handleSubmission}
                  src={ctaMiaou}
                  alt="S'abonner à la newsletter"
                  style={{ verticalAlign: 'top', marginBottom: 0 }}
                />
              </div>
            </div>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5457592.js" />
          </footer>
        ) : (
          ''
        )}
      </div>
    );
  }
}
