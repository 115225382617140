import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import Tada from 'react-reveal/Tada';
import RotatedTitle from '../components/rotated-title';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import Pulse from 'react-reveal/Pulse';
import Layout from '../components/layout';
import indexStyles from './index.module.css';
import chatBubble from '../img/chat-bubble.svg';
import meal from '../img/meal.svg';
import bio from '../img/bio.svg';
import pois from '../img/pois.svg';
import shipping from '../img/shipping.svg';
import recipe1 from '../img/recipe1.svg';
import recipe2 from '../img/recipe2.svg';
import recipe3 from '../img/recipe3.png';
import cat from '../img/cat.png';
import vet from '../img/vet.png';
import cta from '../img/cta.svg';
import cta1 from '../img/cta1.svg';
import insta2 from '../img/insta2.png';
import insta3 from '../img/insta3.png';
import insta4 from '../img/insta4.png';
import insta5 from '../img/insta5.png';
import insta6 from '../img/insta6.png';
import insta7 from '../img/insta7.png';
import Instanimation from '../components/instanimation';

export default class Index extends React.Component {
  state = {
    packagingName: ''
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ packagingName: event.target.value.toLowerCase() });
  }

  render() {
    return (
      <Layout displayFooter location={this.props.location}>
        <Helmet title="Home" />
        {/* SECTION 1 */}
        <section className={indexStyles.sectionHero}>
          {/* DES CROQUETTES A CROQUER */}
          <Tada>
            <RotatedTitle titleTop="des croquettes" titleBottom="à croquer" />
          </Tada>
          <img className={indexStyles.bio} alt="agriculture biologique" src={bio} />
          {/* SUBTITLE */}
          <div className="flex justify-center mt-2">
            <h2 className="flex flex-col items-center" style={{ fontFamily: 'Cabin' }}>
              <div className="text-center">De délicieuses croquettes bio et sans céréales,</div>
              <div className="text-center">adaptées à votre chat et livrées chez vous.</div>
            </h2>
          </div>

          {/* Mon chat s'appelle */}
          <div className={indexStyles.monChat}>
            <span
              className="font-bold"
              style={{ letterSpacing: '0.05em', fontSize: '30px', color: `var(--bisky-green)` }}
            >
              Mon chat s'appelle
            </span>
            <input maxLength="10" autoFocus type="text" value={this.state.packagingName} onChange={this.handleChange} />
            <Link to="/checkout">
              <Bounce left>
                <img
                  src={cta}
                  className={indexStyles.cta}
                  alt="Continuer"
                  style={{ verticalAlign: 'top', marginBottom: 0, marginLeft: '20px' }}
                />
              </Bounce>
            </Link>
            <div className={indexStyles.packagingNameContainer}>
              <p>Le repas bio de</p>
              <p className={indexStyles.packagingName}>{this.state.packagingName}</p>
            </div>
          </div>
          <img src={pois} alt="dots" className={indexStyles.dots} />
        </section>

        {/* SECTION 2 */}
        <section id="section2" className={indexStyles.section} style={{ backgroundColor: 'var(--bisky-pink-pale)' }}>
          <h3
            className="text-center font-bold"
            style={{
              fontSize: '35px',
              letterSpacing: '0.05em',
              textTransform: 'uppercase',
              color: 'var(--bisky-green)'
            }}
          >
            Comment ça marche ?
          </h3>
          <div className={indexStyles.featureSection2}>
            <div className="text-center px-10" style={{ width: '350px' }}>
              <img alt="chat bubble" src={chatBubble} />
              <p
                className="uppercase font-bold"
                style={{ fontSize: '18px', letterSpacing: '0.05em', color: 'var(--bisky-red)', marginTop: '8px' }}
              >
                Faisons connaissance avec votre chat
              </p>
              <p className="font-bold flex-1 text-justify">
                Nous établissons ensemble le profil nutritionnel de votre chat basé sur son activité, sa race, son âge
                et bien d'autres critères...
              </p>
            </div>
            <div className="text-center ml-5 px-10" style={{ width: '350px' }}>
              <img alt="meal" src={meal} />
              <p
                className="uppercase  font-bold"
                style={{ fontSize: '18px', letterSpacing: '0.05em', color: 'var(--bisky-red)', marginTop: '15px' }}
              >
                Une croquette
                <br />
                au poil
              </p>
              <p className="font-bold flex-1 text-justify">
                Votre chat mérite une croquette délicieuse, bio et sans céréales, pensée par vous, conçue par nous, rien
                que pour lui !
              </p>
            </div>
            <div className="text-center ml-5 px-10" style={{ width: '350px' }}>
              <img alt="shipping" src={shipping} />
              <p
                className="uppercase font-bold"
                style={{ fontSize: '18px', letterSpacing: '0.05em', color: 'var(--bisky-red)' }}
              >
                Livraison
                <br /> à domicile
              </p>
              <p className="font-bold flex-1 text-justify">
                Finies les pénuries de croquettes le dimanche soir, finies les crampes aux bras. Nous nous chargeons de
                vous livrer vos croquettes en temps et en heure, selon ses besoins.
              </p>
            </div>
          </div>
          <div className="text-center mt-16">
            <Link to="/">
              <Pulse>
                <img src={cta1} alt="Je teste" style={{ verticalAlign: 'top', margin: 0 }} />
              </Pulse>
            </Link>
            <p className="mt-10 font-bold">
              J'ai besoin d'
              <Link style={{ color: 'black' }} to="/">
                en savoir plus
              </Link>
            </p>
          </div>
        </section>

        {/* SECTION 3 */}
        <section className={indexStyles.section}>
          <RotatedTitle titleTop="du bonheur" titleBottom="dans la gamelle" />

          <div className="flex justify-center mt-10">
            <div className={indexStyles.recipeWrapper}>
              <div className={indexStyles.a}>
                <p className="uppercase font-bold mb-5" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  100% Bio, 100% naturel
                </p>
                <p className="text-justify">
                  Bisky s'engage dans le respect de la nature, de l'animal et de la planète. Toutes nos recettes sont
                  labélisées Agriculture biologique. Nous garantissons l'absence de pesticide mais aussi d'exhausteur de
                  goût chimique, de colorant artificiel ou de conservateur dans la gamelle de votre chat.
                </p>
                <Fade left>
                  <img alt="recipe" className="mt-5 " src={recipe1} />
                </Fade>
              </div>
              <div className={indexStyles.b}>
                <Fade right>
                  <img alt="recipe" className={indexStyles.hideResp} src={cat} />
                </Fade>
                <p className="uppercase font-bold mb-5" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  Croquettes sans céréales
                </p>
                <p className="text-justify">
                  La plupart des croquettes standard pour chats contiennent un taux de céréales trop élevé, qui ne
                  convient pas à l’alimentation du chat et peuvent engendrer chez lui de sérieux problèmes de santé,
                  allant de réactions allergiques au diabète, en passant par l’obésité. Les croquettes sans céréales
                  sont ce qu’il y a de mieux pour votre félin préféré !
                </p>
              </div>
              <div className={indexStyles.c}>
                <p className="uppercase font-bold mb-5" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  Made in France
                </p>
                <p className="text-justify">
                  Bisky relève le défi : élaborer l'ensemble de ses croquettes en France. De la recette en passant par
                  les produits bio jusqu'à la production, nous avons choisi de faire confiance au savoir-faire français.
                </p>
                <Fade left>
                  <img alt="recipe" className="mt-5" src={recipe2} />
                </Fade>
              </div>
              <div className={indexStyles.d}>
                <Fade right>
                  <img alt="recipe" src={recipe3} className={indexStyles.hideResp} />
                </Fade>
                <p className="uppercase font-bold my-5" style={{ fontSize: '18px', color: 'var(--bisky-red)' }}>
                  Haute teneur en viande fraiche et protéines
                </p>
                <p className="text-justify">
                  Comme le chat est un véritable carnivore, son régime alimentaire naturel est essentiellement constitué
                  de viande fraîche ; c'est pourquoi chez Bisky nous nous engageons à respecter ses besoins alimentaires
                  en lui offrant une recette bio riche en protéines. Réveillons le tigre qui sommeille dans votre chat !
                </p>
              </div>
            </div>
          </div>

          <div className="text-center mt-16">
            <Link to="/">
              <Pulse>
                <img src={cta1} alt="Je teste" style={{ verticalAlign: 'top', margin: 0 }} />
              </Pulse>
            </Link>
            <p className="mt-10 font-bold">
              J'ai besoin d'
              <Link style={{ color: 'black' }} to="/">
                en savoir plus
              </Link>
            </p>
          </div>
        </section>

        {/* SECTION 4 */}
        <section
          className={indexStyles.sectionVet}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '6rem',
            paddingBottom: '6rem'
          }}
        >
          <div
            className={indexStyles.expertiseVet}
            style={{ maxWidth: '920px', display: 'flex', alignItems: 'center' }}
          >
            <Flip top>
              <img alt="vet" src={vet} style={{ width: '200px', height: '200px' }} />
            </Flip>
            <div className="ml-10">
              <h3
                className="font-bold"
                style={{
                  fontSize: '35px',
                  letterSpacing: '0.05em',
                  textTransform: 'uppercase',
                  color: 'var(--bisky-green)'
                }}
              >
                Des recettes uniques élaborées par notre vétérinaire
              </h3>
              <p className="mt-5 font-bold text-justify">
                Chaque composant de nos croquettes est minutieusement choisi, pesé et dosé pour former la croquette
                parfaite : une croquette bio sans céréales, avec beaucoup de viande fraîche et une haute teneur
                protéines. Laure Hulot, du centre vétérinaire de Lyon La Duchère, nous aide à concoter ces dosages
                minutieux pour votre compagnon à quatre pattes.
              </p>
              <Link to="/">
                <img src={cta1} alt="Je teste" style={{ verticalAlign: 'top', margin: 0 }} />
              </Link>
            </div>
          </div>
        </section>

        {/* SECTION 5 */}
        <section className={indexStyles.section}>
          <h3
            className="text-center font-bold mt-10"
            style={{ fontSize: '35px', letterSpacing: '0.05em', textTransform: 'uppercase' }}
          >
            Encore plus de raisons de nous faire confiance :
          </h3>

          <div className={indexStyles.featureSection2}>
            <div className="text-center px-10" style={{ width: '350px' }}>
              <img alt="chat bubble" src={chatBubble} />
              <p
                className="uppercase font-bold"
                style={{ fontSize: '18px', letterSpacing: '0.05em', color: 'var(--bisky-red)', marginTop: '7px' }}
              >
                Vous êtes
                <br />
                le maître
              </p>
              <p className="font-bold flex-1 text-justify">
                Un nouveau chat vient de rejoindre votre foyer ? Vous désirez retarder une livraison ? Votre chat fait
                la fine-bouche ? Pas de panique, nous sommes là pour vous aider.
              </p>
            </div>
            <div className="text-center ml-5 px-10" style={{ width: '350px' }}>
              <img alt="meal" src={meal} />
              <p
                className="uppercase font-bold"
                style={{ fontSize: '18px', letterSpacing: '0.05em', color: 'var(--bisky-red)', marginTop: '15px' }}
              >
                SANS
                <br />
                ENGAGEMENT
              </p>
              <p className="font-bold text-justify">
                Arrêtez votre abonnement quand vous le désirez et sans préavis. C'est votre chat qui va être déçu...
              </p>
            </div>
            <div className="text-center ml-5 px-10" style={{ width: '350px' }}>
              <img alt="shipping" src={shipping} />
              <p
                className="uppercase font-bold"
                style={{ fontSize: '18px', letterSpacing: '0.05em', color: 'var(--bisky-red)' }}
              >
                UN GESTE POUR NOTRE PLANETE
              </p>
              <p className="font-bold text-justify">
                L'équipe Bisky s'engage à produire des croquettes 100% naturelles, respectueuses de l'environnement, de
                votre chat et de la planète
              </p>
            </div>
          </div>

          <div className="flex justify-center">
            <div className={indexStyles.instaGridContainer}>
              <Zoom>
                <img alt="instacat" src={insta2} style={{ gridArea: 'a', minWidth: '90px' }} />
              </Zoom>
              <Zoom>
                <img alt="instacat" src={insta3} style={{ gridArea: 'b', minWidth: '90px' }} />
              </Zoom>
              <Zoom>
                <img alt="instacat" src={insta4} style={{ gridArea: 'c', minWidth: '90px' }} />
              </Zoom>
              <Zoom>
                <img alt="instacat" src={insta5} style={{ gridArea: 'd', minWidth: '90px' }} />
              </Zoom>
              <Zoom>
                <img alt="instacat" src={insta6} style={{ gridArea: 'e', minWidth: '90px' }} />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta7}
                  style={{ gridArea: 'f', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta7}
                  style={{ gridArea: 'g', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta4}
                  style={{ gridArea: 'h', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <div
                  className="text-center"
                  style={{
                    gridArea: 'i',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }}
                >
                  <Instanimation />
                </div>
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta2}
                  style={{ gridArea: 'j', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta4}
                  style={{ gridArea: 'k', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta6}
                  style={{ gridArea: 'l', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta5}
                  style={{ gridArea: 'm', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta7}
                  style={{ gridArea: 'n', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
              <Zoom>
                <img
                  alt="instacat"
                  src={insta3}
                  style={{ gridArea: 'o', minWidth: '90px' }}
                  className={indexStyles.hideResp}
                />
              </Zoom>
            </div>
          </div>
          <div className="flex justify-center mt-16">
            <Link to="/">
              <img src={cta1} alt="Je teste" style={{ verticalAlign: 'top', margin: 0 }} />
            </Link>
          </div>
        </section>
      </Layout>
    );
  }
}
