import React from 'react';
import style from './instanimation.module.css';

export default class Instanimation extends React.Component {
  render() {
    return (
      <h2 className={style.sentence}>
        Bisky régale déjà les babines de...
        <div className={style.horizontalFlip}>
          <span>O'Malley</span>
          <span>Duchesse</span>
          <span>Toulouse</span>
          <span>Edgar</span>
          <span>Marie</span>
        </div>
      </h2>
    );
  }
}
